<template>
  <div class="newest">
    <div class="tabBar">
      <h4>최신 앨범</h4>
      <div class="buttonBox">
        <button class="tabButton on" @click="tabClick()">국내</button>
        <button class="tabButton" @click="tabClick()">해외</button>
      </div>
    </div>
    <div v-if="tab==0" class="tab">
      <div v-for="a in newest" :key="a" class="newBox">
        <div class="cover" :style="`background-image : url(${a.albumCover})`"></div>
        <div class="songName">{{a.songName}}</div>
        <div class="singerName">{{a.singerName}}</div>
      </div>
    </div>

    <div v-if="tab==1" class="tab">
      <div v-for="a in foreign" :key="a" class="newBox">
        <div class="cover" :style="`background-image : url(${a.albumCover})`"></div>
        <div class="songName">{{a.songName}}</div>
        <div class="singerName">{{a.singerName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapState
  } from 'vuex';

  export default {
    name: 'new',
    data(){
      return{
        tab:0,
      }
    },
    computed: {
      ...mapState(['newest', 'foreign'])
    },
    methods:{
      tabClick(){
        const tabButton = document.querySelectorAll('.tabButton');
        if(this.tab==0){
          this.tab++;
          tabButton[1].classList.add('on');
          tabButton[0].classList.remove('on');
        }
        else{
          this.tab--;
          tabButton[1].classList.remove('on');
          tabButton[0].classList.add('on');
        }
      }
    }
  }
</script>

<style lang="scss">
  @import './../assets/css/common.css';
  @import './../assets/css/new.scss';
</style>