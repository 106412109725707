<template>

  <section class="chart">
    <div>
      <h4>오늘 Top 100</h4>
      <swiper :slidesPerView="'auto'" :space-between="32" navigation :pagination="{ clickable: true } "
        :scrollbar="{ draggable: true }" :centeredSlides="false" @swiper="onSwiper" @slideChange="onSlideChange">

        <swiper-slide v-for="a in today" :key="a" style="width:180px !important" class="newSlider">
          <div class="cover" :style="`background-image : url(${a.albumCover})`"></div>
          <div class="text">
            <div class="rank">
              <div class="ranking">{{a.today}}</div>
              <div class="change" :style="`background-image : url(${a.change})`"></div>
            </div>
            <div class="info">
              <div class="songName">{{a.songName}}</div>
              <div class="singerName">{{a.singerName}}</div>
            </div>
          </div>

        </swiper-slide>
      </swiper>
    </div>
    <div class="musicVideo">
      <h4>뮤직비디오 Top 10</h4>
      <swiper :slidesPerView="'auto'" :space-between="32" navigation :pagination="{ clickable: true } "
        :scrollbar="{ draggable: true }" :centeredSlides="false" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="a in video" :key="a" style="width:339px !important" class="newSlider">
          <a :href="a.linkUrl" target="_blank">
            <div class="cover" :style="`background-image : url(${a.albumCover})`"></div>
            <div class="text">
              <div class="rank">
                <div class="ranking">{{a.video}}</div>
                <div class="change"></div>
              </div>
              <div class="info">
                <div class="songName">{{a.songName}}</div>
                <div class="singerName">{{a.singerName}}</div>
              </div>
            </div>
          </a>

        </swiper-slide>
      </swiper>
    </div>
    <div>
      <h4><img src="./../assets/img/billboard.png" alt="" style="height:16px; margin-right:10px">K-POP 100</h4>
      <swiper :slidesPerView="'auto'" :space-between="32" navigation :pagination="{ clickable: true } "
        :scrollbar="{ draggable: true }" :centeredSlides="false" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="a in bill" :key="a" style="width:180px !important" class="newSlider">
          <div class="cover" :style="`background-image : url(${a.albumCover})`"></div>
          <div class="text">
            <div class="rank">
              <div class="ranking">{{a.bill}}</div>
              <div class="change" :style="`background-image : url(${a.change})`"></div>
            </div>
            <div class="info">
              <div class="songName">{{a.songName}}</div>
              <div class="singerName">{{a.singerName}}</div>
            </div>
          </div>

        </swiper-slide>
      </swiper>
    </div>
  </section>

</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from 'swiper/vue';
  import {
    mapMutations,
    mapState
  } from 'vuex';

  export default {
    name: 'chart',
    computed: {
      ...mapState(['today', 'bill', 'video'])
    },
    methods:{
    ...mapMutations(['playTrack'])
    },
    components: {
      Swiper,
      SwiperSlide,
    },
  }
</script>

<style lang="scss">
  @import './../assets/css/common.css';
  @import './../assets/css/chart.scss';
</style>