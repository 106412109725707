import {
    createStore
} from 'vuex'
import newest from './newest.json'
import foreign from './foreign.json'
import list from './list.json'
import video from './video.json'

const store = createStore({
    state() {
        return {
            newest: newest,
            foreign: foreign,
            video: video,
            list: [...list],
            today: list,
            bill: [],
            myList: [],
            random: [],
            playNow: 0,
            thisSong: '',
            playToggle: false,
            audio: null,
            linkNum: 0,
            endToggle: false,
            clickToggle: 0,

        }
    },
    mutations: {
        setMore(state, pData) {
            state.data.push(pData);
            state.pageNum++;
        },

        like(state, i) {
            if (state.data[i].liked == false) {
                state.data[i].likes++;
                state.data[i].liked = true;
            } else {
                state.data[i].likes--;
                state.data[i].liked = false
            }
        },

        billSort(state) {
            state.bill = state.list.sort(function (a, b) {
                return a.bill - b.bill
            })
        },

        nextTrack(state) {
            if (state.playNow < state.list.length) {
                let music = document.querySelector('audio');
                state.playNow++;
                music.pause();
                music.src = state.today[state.playNow].linkUrl;
                music.load();
                music.play();
            }
        },

        prevTrack(state) {

            let music = document.querySelector('audio');
            if (state.clickToggle == 0) {
                music.currentTime = 0;
                if (state.playNow > 0) {
                    state.clickToggle++
                }
            } else {
                if (state.playNow > 0) {
                    state.playNow--;
                    music.pause();
                    music.src = state.today[state.playNow].linkUrl;
                    music.load();
                    music.play();
                    state.clickToggle--
                }
            }

        },

        playTrack(state, i) {
            let music = document.querySelector('audio');
            state.playNow = i - 1;
            music.pause();
            music.src = state.today[state.playNow].linkUrl;
            music.load();
            music.play();
        },

        addOn(state, a) {
            state.linkNum = a;
            const routerLink = document.querySelectorAll('.router-link');
            for (let i = 0; i < routerLink.length; i++) {
                routerLink[i].classList.remove('on')
            }
            routerLink[state.linkNum].classList.add('on')
        },

        addTrack(state, i){
            let postData = i;
            if(!state.myList.includes(i)){
                state.myList.unshift(postData)
            }
            else{
                state.myList = state.myList.filter(function(item){
                    return item !== i
                });
                state.myList.unshift(postData)
            }
        },
        removeTrack(state, i){
            state.myList = state.myList.filter(function(item){
                return item !== i
            });
        }

        

    },
    actions: {
        shuffleTrack(context) {
            let i = Math.floor(Math.random() * (20 - 2)) + 1
            context.commit('playTrack', i)
        }
    }

})

export default store