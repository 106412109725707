import { createApp } from 'vue'
import App from './App.vue'
import store from './store.js'
import axios from 'axios'
import router from'./router.js'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

// Vue 3.x
// The second argument is optional and sets the default config values for every player.


let app;
app = createApp(App);
app.config.globalProperties.axios = axios;

app.use(store).use(router).use(VuePlyr).mount('#app')



