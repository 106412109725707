<template>
  <nav>
    <div class="logoBox">
      <img src="./assets/img/logo.svg" alt="">
      <button><img src="./assets/img/search.svg" alt=""></button>
    </div>
    <div class="profile">
      <div class="userImg"></div>
      <p>독고말숙</p>
      <button><img src="./assets/img/downArrow.svg" alt=""></button>
    </div>
    <div class="menu">
      <button @click="addOn(0)"><router-link to="/" class="router-link on" ><div></div>투데이</router-link></button>
      <button @click="addOn(1)"><router-link to="/Chart" class="router-link" ><div></div>차트</router-link></button>
      <button @click="addOn(2)"><router-link to="/new" class="router-link" ><div></div>최신앨범</router-link></button>
      <button @click="addOn(3)"><router-link to="/" class="router-link" ><div></div>DJ 스테이션</router-link></button>
      <button @click="addOn(4)"><router-link to="/" class="router-link" ><div></div>VIBE MAG</router-link></button>
      <button  @click="addOn(5)"><router-link to="/" class="router-link"><div></div>이달의 노래</router-link></button>
    </div>
  </nav>
  <router-view></router-view>
  <div class="bar">
    <div class="barBg">
      <div class="songInfo">
        <div class="coverRank" :style="`background-image : url(${today[playNow].albumCover})`"></div>
        <div class="songTitle">
          <p>{{today[playNow].songName}} <br><span>{{today[playNow].singerName}}</span></p>
        </div>
      </div>
      <div class="playButton">
        <!-- <button class="shuffle" @click="shuffleTrack"></button> -->
        <button class="shuffle" @click="toggle"></button>
        <button v-if="shuffleToggle==false" class="prev" @click="prevTrack"></button>
        <button  v-if="shuffleToggle==true" class="prev" @click="shuffleTrack"></button>
        <button class="play"></button>
        <button v-if="shuffleToggle==false" class="next" @click="nextTrack"></button>
        <button v-if="shuffleToggle==true" class="next" @click="shuffleTrack"></button>
        <button class="rotate"></button>
      </div>
      <div class="barRight">
        <vue-plyr>
          <audio >
            <source :src="today[playNow].linkUrl" type="audio/mp3"/>
          </audio>
        </vue-plyr>
        <button class="list" @click="listButton"></button>
      </div>
      
    </div>
  </div>
  <div class="playList">
    <div v-for="a in myList" :key="a">
      <div class="songInfo">
        <div @click="playTrack(a.today)" class="plCover" :style="`background-image: url(${a.albumCover})`"></div>
        <div class="songTitle">
          <p>{{a.songName}}
            <br> <span>{{a.singerName}}</span>
          </p>
        </div>
      </div>
      <button class="remove" @click="removeTrack(a)">x</button>
      
    </div>
  </div>

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'App',
  data(){
    return{
      shuffleToggle:false,
      listToggle:false,
    }
  },

  computed:{
    ...mapState(["today", "playNow", "playToggle", 'clickToggle', 'current', "myList"])
  },
  methods:{
    ...mapMutations(['nextTrack', 'playTrack', 'prevTrack', 'billSort', 'addOn', 'autoTrack', 'removeTrack']),
    ...mapActions(['shuffleTrack']),
    toggle(){
      const shuffle = document.querySelector('.shuffle');
      if(this.shuffleToggle == false){
        this.shuffleToggle = true;
        shuffle.classList.add('on');
      } 
      else{
        this.shuffleToggle = false;
        shuffle.classList.remove('on');
      }
    },
    listButton(){
      const list = document.querySelector('.list');
      const playList = document.querySelector('.playList');
      if(this.listToggle == false){
        list.classList.add('on')
        playList.classList.add('on')
        this.listToggle = true;
      }
      else{
        list.classList.remove('on')
        playList.classList.remove('on')
        this.listToggle = false;

      }

    }
  },
  
  created(){
    this.billSort()
    
  },
  watch:{
    current: function(){
      
    }
  },
  components: {
  },

  mounted(){
  }
}
</script>

<style lang ="scss">
  
  @import './assets/css/common.css';
  @import './assets/css/main.scss';
  
</style>

