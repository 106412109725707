<template>
  <section class="today">
    <h4>금주의 아티스트</h4>
    <h1>태연 ‘Weekend’ <br>
      살랑살랑 여름을 부르는 주문</h1>
    <p>'믿고 듣는 보컬퀸' 태연, 싱글 'Weekend' 공개! <br>
      톡톡 튀는 휴일 바이브+달콤 보이스, 여름 강타 예고!</p>

    <div class="taeyeon">
      <img src="./../assets/img/taeyeon.png" alt="">
      <div class="songTitle">
        <p>Weekend <br><span>태연(Taeyeon)</span></p>
      </div>
    </div>

    <div class="new">
      <h4>최신앨범</h4>
      <swiper :slidesPerView="'auto'" :space-between="52" navigation :pagination="{ clickable: true } "
        :scrollbar="{ draggable: true }" :centeredSlides="false" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="(a,i) in 10" :key="a" style="width:160px !important" class="newSlider">
          <div class="cover" :style="`background-image : url(${newest[i].albumCover})`"></div>
          <div class="songName">{{newest[i].songName}}</div>
          <div class="singerName">{{newest[i].singerName}}</div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="homeChart">
      <div>
        <h4>오늘 Top 100</h4>
        <ul>
          <li v-for="(a,i) in 5" :key="a">
            <div class="songInfo">
              <div class="coverRank" :style="`background-image : url(${today[i].albumCover})`"></div>
              <div class="songTitle">
                <p>{{today[i].songName}} <br><span>{{today[i].singerName}}</span></p>
              </div>
            </div>
            <div class="buttons">
              <button @click="playTrack(today[i].today)"><img src="../assets/img/play.svg" alt=""></button>
              <button @click="addTrack(today[i])"><img src="../assets/img/add.svg" alt=""></button>
              <button></button>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <h4>빌보드 Top 100</h4>
        <ul>
          <li v-for="(a,i) in 5" :key="a">
            <div class="songInfo">
              <div class="coverRank" :style="`background-image : url(${bill[i].albumCover})`"></div>
              <div class="songTitle">
                <p>{{bill[i].songName}} <br><span>{{bill[i].singerName}}</span></p>
              </div>
            </div>
            <div class="buttons">
              <button @click="playTrack(bill[i].today)"><img src="../assets/img/play.svg" alt=""></button>
              <button @click="addTrack(bill[i])"><img src="../assets/img/add.svg" alt=""></button>
              <button></button>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <h4>최근 들은 노래</h4>
        <ul>
          <li v-for="(a,i) in 5" :key="a">
            <div class="songInfo">
              <div class="coverRank" :style="`background-image : url(${today[i].albumCover})`"></div>
              <div class="songTitle">
                <p>{{today[i].songName}} <br><span>{{today[i].singerName}}</span></p>
              </div>
            </div>
            <div class="buttons">
              <button><img src="../assets/img/play.svg" alt=""></button>
              <button><img src="../assets/img/add.svg" alt=""></button>
              <button></button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from 'swiper/vue';
  import 'swiper/swiper.scss';
  import {
    mapState, mapMutations
  } from 'vuex'


  export default {
    name: 'main',
    data() {
      return {

      }
    },

    computed: {
      ...mapState(["newest", "today", "bill", "playNow"])
    },

    components: {
      Swiper,
      SwiperSlide,
    },

    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {},

      ...mapMutations(['billSort', 'playTrack', 'addTrack'])
    },

    mounted(){
      
    },
    created(){
      
    }

  }
</script>

<style lang="scss">
  @import './../assets/css/common.css';
  @import './../assets/css/home.scss';
</style>