import { createWebHistory, createRouter } from "vue-router";
import Main from './components/Main.vue'
import Chart from './components/Chart.vue'
import New from './components/New.vue'
const routes = [
  {
    path: "/new",
    component: New,
  },

  {
    path: "/chart",
    component: Chart,
  },

  {
    path: "/",
    component: Main,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router; 